<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Hidden",
};
</script>

<style lang="scss" scoped>
@import "~bootswatch/dist/darkly/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
</style>
